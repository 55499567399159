import React from 'react';
import { getSliceItems, getSliceTitle } from '../utils/prismicHelpers';

export const ContentContext = React.createContext([]);

export const ContentProvider = ({ content, children }) => {
  const contentValue = Object.assign(
    {},
    {
      id: content?.uid,
      labels: getSliceItems(content, 'label'),
      richlabels: getSliceItems(content, 'richlabel'),
      contacts: {
        items: getSliceItems(content, 'contacts'),
        title: getSliceTitle(content, 'contacts'),
      },
    }
  );
  return <ContentContext.Provider value={contentValue}>{children}</ContentContext.Provider>;
};
