import React, { useContext } from 'react';
import { ContentContext } from '../store/contentProvider';
import { getLabelValue, renderRichText } from '../utils/prismicHelpers';

export const Header = () => {
  const { labels } = useContext(ContentContext);
  return (
    <header>
      <div>
        <h1>{getLabelValue(labels, 'name')}</h1>
        <div class="sub-heading">
          <p>{getLabelValue(labels, 'title')}</p>
        </div>
      </div>
    </header>
  );
};

export default Header;
