import React, { useEffect } from 'react';
import Prismic from '@prismicio/client';
import { RichText } from 'prismic-reactjs';
import { Client, renderRichText } from '../utils/prismicHelpers';
import { linkResolver } from '../config/prismic';
import JavaScript from '../assets/icons/javascript.svg';
import ReactJS from '../assets/icons/react.svg';

function Skills(props) {
  const [docContent, setDocContent] = React.useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(Prismic.Predicates.at('document.type', 'skill'), { lang: '*' });
      setDocContent(response?.results?.[0]?.data);
    };
    fetchData();
  }, []);
  return (
    <div id="skills" className="skills">
      <span class="section-heading">{renderRichText(docContent?.title)}</span>
      <div class="section-content">
        <ul>
          {docContent?.body?.map((item) => (
            <li>
              {renderRichText(item?.primary?.skill_title)}
              {item.items.map((skill) => skill.skill_name).join(', ')}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Skills;
