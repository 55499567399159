import React, { useContext } from 'react';
import { ContentContext } from '../store/contentProvider';
import { getLabelValue, renderRichText } from '../utils/prismicHelpers';

export const About = () => {
  const { richlabels } = useContext(ContentContext);

  return (
    <div id="about">
      <div class="section-content">{renderRichText(getLabelValue(richlabels, 'summary'))}</div>
    </div>
  );
};

export default About;
