import React, { useEffect } from "react";

function Stars(props) {
  useEffect(() => {
    import(/* webpackChunkName: "particles" */ "particles.js")
      .then(({ default: _ }) => {
        const particlesJS = window.particlesJS;
        if (particlesJS) {
          particlesJS.load("stars", "public/config.json", () => {
            document.getElementById("stars").classList.add("bang");
          });
        }
      })
      .catch((error) => "An error occurred while loading the component");
  }, []);

  return (
    <div id="stars">
      {/* <video autoplay muted loop="loop" src="public/videos/stars.mov"></video> */}
    </div>
  );
}

export default Stars;
