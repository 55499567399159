import React, { useEffect } from 'react';
import Prismic from '@prismicio/client';
import { Client } from '../utils/prismicHelpers';
import { ContentProvider } from '../store/contentProvider';
import Stars from './Stars';
import Header from './Header';
import About from './About';
import Skills from './Skills';
import Contact from './Contact';
import Earth from './Earth';

function App(props) {
  const [docContent, setDocContent] = React.useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(Prismic.Predicates.at('document.type', 'copy'));
      setDocContent(response.results[0]);
    };
    fetchData();
  }, []);

  return (
    <ContentProvider content={docContent}>
      <Stars />
      <div class="home container">
        <main class="content">
          <Header />
          <section>
            <About />
          </section>

          <section>
            <Skills />
          </section>

          <section>
            <Contact />
          </section>
        </main>
        <Earth />
      </div>
    </ContentProvider>
  );
}

export default App;
