import React from 'react';
import Prismic from '@prismicio/client';
import { Link, RichText, Date } from 'prismic-reactjs';
import { apiEndpoint, accessToken, linkResolver } from '../config/prismic';

// Helper function to convert Prismic Rich Text links to React Link components
export const customLink = (type, element, content, children, index) => (
  <Link to={linkResolver(element.data)} key={index}>
    {content}
  </Link>
);

// Client method to query documents from the Prismic repo
export const Client = Prismic.client(apiEndpoint, { accessToken });

export const getLabelValue = (labels, key) => labels?.find((item) => item.label_key === key)?.label_value;

export const getSliceItems = (content, key) => content?.data?.body?.find((item) => item.slice_type === key)?.items;

export const getSliceTitle = (content, key) => content?.data?.body?.find((item) => item.slice_type === key)?.primary?.title;

export const renderRichText = (node) => RichText.render(node, linkResolver);

export const renderLink = (link) => Link.url(link, linkResolver);
