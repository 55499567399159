// -- Prismic API endpoint
// Determines which repository to query and fetch data from
// Configure your site's access point here
export const apiEndpoint = 'https://mbr-portfolio-labels.cdn.prismic.io/api/v2';

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = 'MC5YLXpGcGhBQUFDTUFqdHMz.QVTvv73vv73vv73vv71v77-9a--_ve-_vQM777-9eu-_ve-_ve-_vWPvv73vv70_Te-_ve-_vRvvv73vv71RI3_vv70';

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
  if (doc.type === 'post') return `/blog/${doc.uid}`;
  return '/';
};
