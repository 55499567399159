import React, { useContext } from 'react';
import { ContentContext } from '../store/contentProvider';
import { renderRichText, renderLink } from '../utils/prismicHelpers';

function Contact(props) {
  const { contacts } = useContext(ContentContext);
  return (
    <div id="contact">
      <span class="section-heading">{renderRichText(contacts?.title)}</span>
      <div>
        {contacts?.items?.map((item) => {
          let target = {};
          if (item.contact_link.target) {
            target = {
              target: item.contact_link.target,
              rel: 'noopener',
            };
          }
          return (
            <a class="contact-link" href={renderLink(item.contact_link)} {...target} download>
              {item.contact_text}
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default Contact;
